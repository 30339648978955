import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router-dom";
import downloadIcon from "../img/download.svg";
import shareIcon from "../img/share.svg";

import { isIOS } from "react-device-detect";
import { getFilesByUrl } from "../services/url";

const checkDate = (date) => {
  let currentDate = new Date().toISOString().substring(0, 10);

  if (date >= currentDate) return true;

  return false;
};
const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
const IconsContainer = styled.div`
  position: relative;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  order: 2;
`;
const IconsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
`;
const IconDownloadBox = styled.a``;
const IconShareBox = styled.a``;
const Icon = styled.img`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: 0 10px;
  opacity: 0.5;
  &:active,
  &:focus {
    cursor: pointer;
    opacity: 1;
  }
  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
  }
`;
const Video = styled.video`
  width: 100%;
  height: 100%;
`;
const Source = styled.source``;

const realWidth = window.innerWidth;

const size = [
  {
    id: 0,
    width: 1080,
    responsiveWidth: "70%",
  },

  {
    id: 1,
    width: 1920,
    responsiveWidth: "60%",
  },

  {
    id: 2,
    width: 2560,
    responsiveWidth: "60%",
  },

  {
    id: 3,
    width: 1024,
    responsiveWidth: "60%",
  },

  {
    id: 4,
    width: 1366,
    responsiveWidth: "60%",
  },

  {
    id: 5,
    width: 1536,
    responsiveWidth: "60%",
  },
];

let valwidth;
let respwidth;

size.forEach((value, index) => {
  if (realWidth === value.width) {
    respwidth = value.responsiveWidth;
    valwidth = value.width;
  }
});

const ImageBox = styled.div`
  width: auto;
  height: ${realWidth === valwidth || realWidth >= valwidth
    ? respwidth
    : "65%"};
`;
const Image = styled.img`
  width: auto;
  height: 100%;
`;

const SpinnerLoader = styled.div`
  border: 16px solid #f3f3f320;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 250px;
  height: 250px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Home = () => {
  let { base64 } = useParams();
  const [files, setFiles] = useState();
  const [base64Data, setBase64Data] = useState();
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (base64) {
      localStorage.setItem("base64Data", base64);
      setBase64Data(base64);
    } else {
      if (localStorage.getItem("base64Data")) {
        setBase64Data(localStorage.getItem("base64Data"));
      }
    }
  }, [base64]);

  useEffect(() => {
    if (base64Data) {
      try {
        const dataObject = JSON.parse(atob(decodeURIComponent(base64Data)));
        console.log(dataObject);

        const dateIsValid = checkDate(dataObject.expiration);

        const saneUrl = decodeURI(dataObject.url);

        //window.history.replaceState(null, null, "/");
        if (dateIsValid) {
          const getFiles = async () => {
            const handleFiles = await getFilesByUrl(saneUrl);
            setFiles(handleFiles);
          };
          getFiles();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [base64Data]);

  useEffect(() => {
    if (files && files.length > 0) {
      const shareData = {
        files: files,
      };

      const btn = document.querySelector("#share-button");

      if (navigator.canShare && navigator.canShare(shareData)) {
        btn.addEventListener("click", async () => {
          navigator.share(shareData);
        });
      }
    }
  }, [files]);

  if (files && files.length > 0) {
    return (
      <MainContainer>
        <IconsContainer>
          <IconsBox>
            <IconDownloadBox
              href={files[0].src}
              target={isIOS ? "_blank" : "_self"}
              download={files[0].name || "download"}
            >
              <Icon src={downloadIcon} width={45} height={45} />
            </IconDownloadBox>
            {navigator.canShare ? (
              <IconShareBox id="share-button">
                <Icon src={shareIcon} width={27} height={27} />
              </IconShareBox>
            ) : null}
          </IconsBox>
        </IconsContainer>

        <ImageBox>
          {files[0].type.includes("video") ? (
            <Video
              onClick={(e) => {
                if (isMuted) {
                  e.preventDefault();
                  setIsMuted(false);
                }
              }}
              controls
              autoPlay
              loop
              muted={isMuted}
              playsInline
            >
              <Source src={files[0].src} />
            </Video>
          ) : (
            <Image src={files[0].src} />
          )}
        </ImageBox>
      </MainContainer>
    );
  } else {
    return <SpinnerLoader />;
  }
};
