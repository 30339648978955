import Mime from "mime";

const getFilesByUrl = async (url) => {
  let moddedUrl = url;
  const type = Mime.getType(url);
  const extension = Mime.getExtension(type);

  if (type.includes("matroska")) {
    moddedUrl = url.replace("mkv", "mp4");
  }
  const response = await fetch(moddedUrl);
  const blob = await response.blob();
  const filesArray = [
    new File([blob], `download.${extension}`, { type: blob.type }),
  ];

  filesArray.forEach((file) => {
    file.src = URL.createObjectURL(file);
  });

  return filesArray;
};

export { getFilesByUrl };
